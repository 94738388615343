import React, { useState } from 'react';
import { StyledForm } from './styles/main.js';
import Button from '../../components/Button';
import Input from '../../components/Input';
import axios, { baseUrl } from '../../utils/axios';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { urls } from '../Router/urls';

const Form = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post(`${baseUrl}/api/login`, {
        email,
        password,
        internalDashboard: true,
      });
      if (res.status === 200) {
        dispatch({
          type: 'auth/login',
          payload: { token: res.data.token, userType: res.data.userType },
        });
        history.push(urls.listOrganizations);
      }
    } catch (err) {
      message.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledForm onSubmit={submit}>
      <Input
        inputType="auth"
        placeholder="Email"
        mb="1rem"
        type="email"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Input
        inputType="auth"
        placeholder="Password"
        required
        mb="1.5rem"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <br />
      <Button
        btnType="auth"
        text="Login"
        htmlType="submit"
        disabled={loading}
      />
    </StyledForm>
  );
};

export default Form;
