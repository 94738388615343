import React, { useContext, useState } from 'react';
import { SectionHeading, Responsive } from '../styles/main';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { FlexContainer } from '../../../components/Common';
import axios, { baseUrl } from '../../../utils/axios';
import { message } from 'antd';
import { OrgUUIDContext } from '../../../context';

const Info = ({ data: { name, code, status, address } }) => {
  const orgUUID = useContext(OrgUUIDContext);

  const [loading, setLoading] = useState(false);
  const [addressState, setAddressState] = useState(address);

  const editInfo = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.put(`${baseUrl}/api/organization/${orgUUID}`, {
        address: addressState || null,
      });
      message.success('Successfully updated!');
    } catch (err) {
      message.error('Error updating info');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Responsive>
      <FlexContainer justify="space-between">
        <SectionHeading mb="1rem">Edit Information</SectionHeading>
        <span>
          <b>Status:&nbsp;&nbsp;</b>
          {status || 'N/A'}
        </span>
      </FlexContainer>
      <form onSubmit={editInfo}>
        <Input
          inputType="standardForm"
          label="Organization Name"
          placeholder="Name..."
          disabled
          mb="0.8rem"
          value={name}
        />
        <Input
          inputType="standardForm"
          label="Organization Code"
          disabled
          placeholder="Code..."
          mb="0.8rem"
          value={code}
        />
        <Input
          inputType="standardTA"
          label="Address"
          placeholder="Address..."
          value={addressState}
          onChange={(e) => setAddressState(e.target.value)}
        />
        <FlexContainer justify="flex-end" mt="1rem">
          <Button btnType="save" small disabled={loading} htmlType="submit" />
        </FlexContainer>
      </form>
    </Responsive>
  );
};

export default Info;
