import React, { useState, useEffect, Fragment, useContext } from "react";
import { SectionHeading } from "../styles/main";
import AddModal from "./AddUserModal";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import Loading from "../../../components/Loading";
import { FlexContainer } from "../../../components/Common";
import { Col, message, Row, Empty } from "antd";
import axios, { baseUrl } from "../../../utils/axios";
import { OrgUUIDContext } from "../../../context";

const Users = () => {
  const [open, setOpen] = useState(false);
  const [userList, setUserList] = useState(null);
  const [loading, setLoading] = useState(true);
  const orgUUID = useContext(OrgUUIDContext);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await axios.get(
          `${baseUrl}/api/organization/users/${orgUUID}`
        );
        setUserList(res.data);
      } catch (err) {
        message.error("Error fetching users");
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, [orgUUID]);

  return (
    <>
      <AddModal
        visible={open}
        footer={null}
        onCancel={() => setOpen(false)}
        setUserList={setUserList}
      />

      <SectionHeading mb="1rem">Users</SectionHeading>
      {loading ? (
        <Loading />
      ) : (
        <Fragment>
          {userList?.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Users Found"
            />
          ) : (
            userList?.map((user, index) => (
              <div className="border-bottom mb-4" key={index}>
                <Row key={index}>
                  <Col xs={24} sm={11} xl={6} className="me-2">
                    <Input
                      inputType="standardForm"
                      value={user.email}
                      label="Email"
                      disabled
                      mb="0.8rem"
                    />
                  </Col>
                  <Col xs={24} sm={10} xl={4} className="me-2">
                    <Input
                      inputType="standardForm"
                      value={`${user?.firstName} ${user?.lastName}`}
                      label="Name"
                      disabled
                      mb="0.8rem"
                    />
                  </Col>
                  <Col xs={24} sm={7} xl={4} className="me-2">
                    <Input
                      inputType="standardForm"
                      value={user.confirmationCode}
                      label="Confirmation Code"
                      disabled
                      mb="0.8rem"
                    />
                  </Col>
                  <Col xs={24} sm={7} xl={4} className="me-2">
                    <Input
                      inputType="standardForm"
                      value={user.resetPasswordCode}
                      label="Reset password Code"
                      disabled
                      mb="0.8rem"
                    />
                  </Col>
                  <Col xs={24} sm={7} xl={4}>
                    <Select
                      selectType="standard"
                      value={user?.role}
                      options={["Admin", "Recruiter"]}
                      label="Role"
                      disabled
                    />
                  </Col>
                </Row>
              </div>
            ))
          )}

          <FlexContainer justify="flex-end" mt="0.2rem">
            <Button
              btnType="standardGreen"
              small
              text="Add User"
              onClick={() => setOpen(true)}
            />
          </FlexContainer>
        </Fragment>
      )}
    </>
  );
};

export default Users;
