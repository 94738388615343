import styled from 'styled-components';
import { Button } from 'antd';

export const StandardGreenButton = styled(Button)`
  min-width: ${(p) => (p.small ? '135px' : '170px')};
  height: 40px;
  background-color: #13cc3e !important;
  color: #fff;
  font-size: 16px;
  margin: 0;
  padding: 0 5px;
  border-radius: 20px;
  font-weight: 500;
  &:hover {
    background-color: #13cc3e !important;
    color: #fff;
    opacity: 0.8;
    outline: none;
    border: none;
  }
`;
