import { Table } from 'antd';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
  /* margin-top: 10px; */
  .ant-table-container {
    .ant-table-content {
      table {
        border-spacing: ${(props) => props.spacing && '0 8px !important'};
        thead.ant-table-thead {
          tr {
            th.ant-table-cell {
              background-color: white !important;
              text-align: ${(p) => p.align || 'center'};
              font-family: 'Montserrat';
              font-size: 18px;
              font-weight: 600;
              color: #5293f5;
              @media (max-width: 575px) {
                text-align: left;
                font-size: 16px;
              }
            }
          }
        }
        tbody.ant-table-tbody {
          tr.ant-table-row {
            td.ant-table-cell {
              margin: 0 !important;
              /* padding: 0 !important; */
              padding-left: 10px !important;
              height: ${(p) => p.cellHeight || '55px'} !important;
              border: 1px solid white;
              background: #e9e9e9;
              /* border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px; */
              font-weight: 500;
              text-align: ${(p) => p.align || 'center'};
              font-size: 14px;

              @media (max-width: 575px) {
                text-align: left;
                font-size: 12px;
              }
            }
          }
          .table_row_pointer {
            @media (max-width: 575px) {
              padding: 1rem 0.5rem !important;
            }
            &:hover {
              opacity: 0.8;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
`;

export const CellText = styled.span`
  color: #000;
  font-family: 'Montserrat';
  letter-spacing: 0.5px;
`;

export const ResponsiveViewHeading = styled.span`
  color: #000;
  opacity: 0.9;
  font-family: 'Montserrat';
  font-weight: 600;
`;
