import { Button } from 'antd';
import { StyledAuthButton } from './styles/authStyles';
import { StyledSaveButton } from './styles/saveStyles';
import { StandardGreenButton } from './styles/standardGreenStyles';

const CustomButton = ({ btnType, text, ...rest }) => {
  const getInput = () => {
    switch (btnType) {
      case 'auth':
        return <StyledAuthButton {...rest}>{text}</StyledAuthButton>;

      case 'save':
        return <StyledSaveButton {...rest}>Save</StyledSaveButton>;

      case 'standardGreen':
        return <StandardGreenButton {...rest}>{text}</StandardGreenButton>;

      default:
        return <Button {...rest}>{text}</Button>;
    }
  };
  return <>{getInput()}</>;
};

export default CustomButton;
