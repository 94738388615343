import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Navbar from '../../components/Navbar';
import { urls } from './urls';

export const PrivateRoute = ({ ...rest }) => {
  const { isLoggedIn } = useSelector((state) => state.auth);

  return isLoggedIn ? (
    <>
      <Navbar /> <Route {...rest} />
    </>
  ) : (
    <Redirect to={urls.login} />
  );
};
