import React, { useState, useContext, useEffect } from 'react';
import { SectionHeading } from '../styles/main';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { FlexContainer } from '../../../components/Common';
import axios, { baseUrl } from '../../../utils/axios';
import { message, Radio } from 'antd';
import { OrgUUIDContext } from '../../../context/organizationUUID';

// PLAN ID 1 = TRIAL

const Plan = ({
  organizationDetails,
  allPlans,
  data: { selectedPlan, priceValue, jobPostings, recruiters },
  handlers: {
    setSelectedPlan,
    setPriceValue,
    setJobPostings,
    setRecruiters,
    setData,
  },
}) => {
  const orgUUID = useContext(OrgUUIDContext);

  const [loading, setLoading] = useState();
  const disableFields = [2, 3].includes(selectedPlan);

  useEffect(() => {
    if (selectedPlan === 2 || selectedPlan === 3) {
      const plan = allPlans.find((p) => p.plan_id === selectedPlan);
      setPriceValue(plan.price);
      setJobPostings(plan.totalJobs);
      setRecruiters(plan.totalRecruiters);
    }
  }, [selectedPlan, allPlans]);

  const editPlan = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let payload = {};
      // if (selectedPlan === 4)
      //   payload = {
      //     planId: 4,
      //     planType: 'CUSTOM',
      //     totalRecruiters: recruiters,
      //     totalJobs: jobPostings,
      //     price: priceValue,
      //   };
      // else
        payload = {
          planId: selectedPlan,
          // planType: allPlans.find((p) => p.plan_id === selectedPlan).name,
        };

      const res = await axios.put(
        `${baseUrl}/api/organization/${orgUUID}`,
        payload
      );
      setData((prev) => ({ ...prev, status: res.data.status }));
      message.success('Successfully updated!');
    } catch (err) {
      message.error('Error creating organization');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SectionHeading mb="1rem">Plan Settings</SectionHeading>

      <form onSubmit={editPlan}>
        <FlexContainer mb="0.8rem" justify="center">
          <Radio.Group
            onChange={(e) => setSelectedPlan(e.target.value)}
            value={selectedPlan || organizationDetails.plan_id}
          >
            <Radio value={1}>Trial</Radio>
            <Radio value={3}>Premium</Radio>
            {/* <Radio value={4}>Custom</Radio> */}
          </Radio.Group>
        </FlexContainer>

        {/* <Input
          inputType="standardForm"
          label="Price Value ($)"
          type="number"
          placeholder="Price..."
          mb="0.8rem"
          value={priceValue}
          onChange={(e) => setPriceValue(e.target.value)}
          disabled={disableFields}
        />
        <Input
          inputType="standardForm"
          label="Job Posting"
          type="number"
          placeholder="Num Of Jobs..."
          value={jobPostings}
          mb="0.8rem"
          onChange={(e) => setJobPostings(e.target.value)}
          disabled={disableFields}
        />
        <Input
          inputType="standardForm"
          label="Recruiters"
          type="number"
          placeholder="Num Of Recruiters..."
          value={recruiters}
          onChange={(e) => setRecruiters(e.target.value)}
          disabled={disableFields}
        /> */}

        <FlexContainer justify="flex-end" mt="1rem">
          <Button btnType="save" small htmlType="submit" disabled={loading} />
        </FlexContainer>
      </form>
    </>
  );
};

export default Plan;
