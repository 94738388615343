import styled from 'styled-components';
import { Navbar, Nav } from 'react-bootstrap';
import bg from '../../assets/login/background.png';

export const StyledNavbar = styled(Navbar)`
  margin-bottom: 2rem;
  padding: 1rem 2rem;
  background-image: url(${bg});
  background-position: center;
  background-size: cover;
  @media (max-width: 992px) {
    padding: 1rem 2rem;
  }

  > button.navbar-toggler {
    &:focus {
      box-shadow: none !important;
    }
  }
`;

export const StyledNavLink = styled(Nav.Link)`
  color: white !important;
  font-family: 'Montserrat';
  font-weight: 600;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
  @media (max-width: 575px) {
    display: flex;
    justify-content: center;
  }
`;
