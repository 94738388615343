import { message } from 'antd';
import React, { useState, useEffect } from 'react';
import axios, { baseUrl } from '../../utils/axios';
import { StyledTable } from '../../components/Table';
import {
  FlexContainer,
  PageContainer,
  PageHeading,
} from '../../components/Common.js';
import { columns } from './columns';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import { urls } from '../Router/urls';

const ListOrganizations = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchOrganizations = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${baseUrl}/api/organization`);
        setData(res?.data);
      } catch (err) {
        message.error(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };
    fetchOrganizations();
  }, []);

  return (
    <PageContainer>
      <FlexContainer justify="space-between" mb="1rem">
        <PageHeading>Admin Dashboard</PageHeading>
        <Button
          btnType="standardGreen"
          text="Create Organization"
          onClick={() => history.push(urls.createOrganization)}
        />
      </FlexContainer>

      <StyledTable
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={{ position: ['bottomRight'], pageSize: 10 }}
        spacing
        align="left"
        rowClassName={(record, index) => `table_row_pointer`}
        onRow={(record, rowIndex) => ({
          onClick: (event) => {
            history.push(`/admin/organizations/${record.uuid}`);
          },
        })}
      />
    </PageContainer>
  );
};

export default ListOrganizations;
