import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: JSON.parse(localStorage.getItem('BcixAdminData'))?.token
    ? true
    : false,
  userType: JSON.parse(localStorage.getItem('BcixAdminData'))?.userType,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      localStorage.setItem(
        'BcixAdminData',
        JSON.stringify({
          token: action.payload.token,
          userType: action?.payload?.userType,
        })
      );
      state.isLoggedIn = true;
      state.userType = action?.payload?.userType;
    },
    logout: (state) => {
      localStorage.removeItem('BcixAdminData');
      state.isLoggedIn = false;
      state.userType = null;
    },
  },
});

// export const { login, logout } = slice.actions;
export default slice.reducer;
