import styled from 'styled-components';

export const StyledFormInput = styled.input`
  width: 100%;
  height: 42px;
  border-radius: 8px;
  border: none;
  outline: none;
  background: ${(p) => p.disabled && '#e9e9e9'};
  cursor: ${(p) => p.disabled && 'not-allowed'};
  font-family: 'Montserrat';
  font-size: 14px;
  padding: 0.5rem 0.7rem;
  font-weight: 500;
  margin-bottom: ${(p) => p.mb || '0'};
  box-shadow: 0 0 0 1.5px #e9e9e9;
  &::placeholder {
    opacity: 0.5;
  }
`;

export const StyledFormTextArea = styled.textarea`
  width: 100%;
  min-height: 150px;
  border-radius: 8px;
  border: none;
  outline: none;
  background: ${(p) => p.disabled && '#e9e9e9'};
  cursor: ${(p) => p.disabled && 'not-allowed'};
  font-family: 'Montserrat';
  font-size: 14px;
  padding: 0.5rem 0.7rem;
  font-weight: 500;
  margin-bottom: ${(p) => p.mb || '0'};
  box-shadow: 0 0 0 1.5px #e9e9e9;
  &::placeholder {
    opacity: 0.5;
  }
`;

export const FormInputLabel = styled.label`
  font-size: 15px;
  font-weight: 600;
  font-family: 'Montserrat';
  margin: 0.5rem 0;
`;
