import styled from 'styled-components';
import { DatePicker } from 'antd';

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  height: 42px;
  border-radius: 8px;
  border: none;
  outline: none;
  background: ${(p) => p.disabled && '#e9e9e9'} !important;
  font-family: 'Montserrat';
  font-size: 14px;
  padding: 0.5rem 0.7rem;
  font-weight: 500;
  margin-bottom: ${(p) => p.mb || '0'};
  box-shadow: 0 0 0 1.5px #e9e9e9;
`;
