import React, { useState, useContext } from 'react';
import moment from 'moment';
import { message } from 'antd';
import axios, { baseUrl } from '../../../utils/axios';
import Button from '../../../components/Button';
import { SectionHeading } from '../styles/main';
import { FormInputLabel } from '../../../components/Input/styles/formInput';
import { StyledDatePicker } from '../../../components/DatePicker';
import { FlexContainer } from '../../../components/Common';
import { OrgUUIDContext } from '../../../context';

const Settings = ({ trialEndDate, setTrialEndDate }) => {
  const orgUUID = useContext(OrgUUIDContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const changeTrialDate = async () => {
    setLoading(true);
    try {
      await axios.put(`${baseUrl}/api/organization/${orgUUID}`, {
        trialEndDate: trialEndDate.toISOString(),
      });
      message.success('Successfully updated!');
      setOpen(false);
    } catch (err) {
      message.error('Error updating');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SectionHeading mb="1rem">Settings</SectionHeading>
      <FormInputLabel>Trial Extension</FormInputLabel>
      <StyledDatePicker
        showToday={false}
        disabledDate={(currentDate) => currentDate.isBefore(moment())}
        value={trialEndDate}
        placeholder={`Current trial valid until:`}
        format={(value) => `Current trial valid until:  ${value.format('LL')}`}
        onChange={(date) => setTrialEndDate(date)}
        open={open}
        onClick={() => setOpen((prev) => !prev)}
        renderExtraFooter={() => (
          <FlexContainer justify="flex-end" mt="10px" mb="10px">
            <Button
              btnType="standardGreen"
              small
              text="Save"
              disabled={loading}
              onClick={changeTrialDate}
            />
          </FlexContainer>
        )}
      />
    </>
  );
};

export default Settings;
