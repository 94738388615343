import { Button } from 'antd';
import { StyledAuthInput } from './styles/authStyles';
import {
  StyledFormInput,
  FormInputLabel,
  StyledFormTextArea,
} from './styles/formInput';

const CustomButton = ({ inputType, text, label, ...rest }) => {
  const getInput = () => {
    switch (inputType) {
      case 'auth':
        return <StyledAuthInput {...rest} />;

      case 'standardForm':
        return (
          <>
            <FormInputLabel>{label}</FormInputLabel>
            <StyledFormInput {...rest} />
          </>
        );

      case 'standardTA':
        return (
          <>
            <FormInputLabel>{label}</FormInputLabel>
            <StyledFormTextArea {...rest} />
          </>
        );
      default:
        return <Button {...rest}>{text}</Button>;
    }
  };
  return <>{getInput()}</>;
};

export default CustomButton;
