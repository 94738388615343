import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { urls } from './urls';

export const OfflineRoute = ({ ...rest }) => {
  const { isLoggedIn } = useSelector((state) => state.auth);

  return !isLoggedIn ? (
    <Route {...rest} />
  ) : (
    <Redirect to={urls.listOrganizations} />
  );
};
