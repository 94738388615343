import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const AuthHeadingText = styled.h2`
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 21px;
  font-weight: 700;
`;

export const AuthLink = styled(Link)`
  border-bottom: 1px solid white;
  color: white;
  font-size: 18px;
  padding: 0;
  margin: 0;
  padding-bottom: 0.15rem;
  font-weight: 500;
  margin-top: ${(p) => p.mt};
  text-decoration: none;
  &:hover {
    color: white;
    border-bottom: 1px solid white;
    opacity: 0.6;
  }
`;
