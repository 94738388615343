import React, { useState, useEffect } from 'react';
import { Row } from 'antd';
import { PageContainer } from '../../components/Common.js';
import { AuthHeadingText, AuthLink } from '../../components/Auth.js';
import { StyledCol, LoginBackgroundContainer, Logo } from './styles/main.js';
import bg from '../../assets/login/background.png';
import logo from '../../assets/logo.svg';
import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import { useLocation } from 'react-router-dom';
import { urls } from '../Router/urls.js';

const Login = () => {
  const { pathname } = useLocation();
  const [page, setPage] = useState(null);

  useEffect(() => {
    if (pathname === urls.login) setPage('login');
    if (pathname === urls.forgotPassword) setPage('forgot-password');
  }, [pathname]);

  return (
    <PageContainer background={`url(${bg})`}>
      <Row>
        <StyledCol xs={24}>
          <Row justify="center">
            <LoginBackgroundContainer xs={24} sm={22} md={18} xl={12}>
              <Logo src={logo} alt="logo" />
              <br />
              {page === 'login' && (
                <>
                  <AuthHeadingText>Login Into Admin Dashboard</AuthHeadingText>
                  <LoginForm />
                  <AuthLink to={urls.forgotPassword} mt="0.5rem">
                    Forgot Password ?
                  </AuthLink>
                </>
              )}
              {page === 'forgot-password' && (
                <>
                  <AuthHeadingText>Reset Password</AuthHeadingText>
                  <ForgotPasswordForm />
                  <AuthLink to={urls.login}>Login to your account ?</AuthLink>
                </>
              )}
            </LoginBackgroundContainer>
          </Row>
        </StyledCol>
      </Row>
    </PageContainer>
  );
};

export default Login;
