import styled from 'styled-components';

export const PageContainer = styled.div`
  padding: 0rem 7rem;
  background: ${(p) => p.background};

  @media (max-width: 768px) {
    padding: 0 3rem;
  }

  @media (max-width: 575px) {
    padding: 0 1rem;
  }
`;

export const FlexContainer = styled.div`
  width: 100%;
  height: ${(p) => p.height};
  display: flex;
  flex-direction: ${(p) => p.direction || 'row'};
  justify-content: ${(p) => p.justify || 'flex-start'};
  align-items: ${(p) => p.align || 'flex-start'};
  margin-top: ${(p) => p.mt || '0'};
  margin-bottom: ${(p) => p.mb || '0'};
`;

export const PageHeading = styled.h1`
  margin: 0;
  padding: 0;
  color: #6b39e8;
  font-family: 'Montserrat';
  font-size: 30px;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 28px;
  }
  @media (max-width: 575px) {
    font-size: 24px;
  }
`;
