import styled from 'styled-components';

export const StyledAuthInput = styled.input`
  border: none;
  outline: none;
  margin: 5px;
  padding: 0.5rem 0.7rem;
  background: transparent;
  border-bottom: 1px solid white;
  font-weight: 500;
  font-size: 17px;
  width: 500px;
  color: white;
  margin-bottom: ${(p) => p.mb};
  &::placeholder {
    color: white;
    font-weight: 500;
  }

  @media (max-width: 1024px) {
    width: 90%;
  }
`;
