import React, { useState } from 'react';
import { StyledForm } from './styles/main.js';
import Button from '../../components/Button';
import Input from '../../components/Input';
import axios, { baseUrl } from '../../utils/axios';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import { urls } from '../Router/urls';

const ForgotPasswordForm = () => {
  const history = useHistory();

  const [reset, setReset] = useState(false);

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [otp, setOTP] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const sendCode = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post(`${baseUrl}/api/reset-password-code`, { email });
      setReset(true);
    } catch (err) {
      message.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    try {
      if (password !== confirmPassword)
        throw new Error('Password fields mismatch!');

      await axios.post(`${baseUrl}/api/reset-password`, {
        code: otp,
        email,
        newPassword: password,
      });
      history.push(urls.login);
    } catch (err) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return !reset ? (
    <StyledForm onSubmit={sendCode}>
      <Input
        inputType="auth"
        placeholder="Email"
        mb="1rem"
        type="email"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <br />
      <Button
        btnType="auth"
        text="Send Code"
        htmlType="submit"
        disabled={loading}
      />
    </StyledForm>
  ) : (
    <StyledForm onSubmit={submit}>
      <Input
        inputType="auth"
        placeholder="OTP"
        mb="1rem"
        type="text"
        required
        value={otp}
        onChange={(e) => setOTP(e.target.value)}
      />
      <Input
        inputType="auth"
        placeholder="New Password"
        required
        mb="1.5rem"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Input
        inputType="auth"
        placeholder="Confirm Password"
        required
        mb="1.5rem"
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <br />
      <Button
        btnType="auth"
        text="Reset Password"
        htmlType="submit"
        disabled={loading}
      />
    </StyledForm>
  );
};

export default ForgotPasswordForm;
