import { Select } from 'antd';
import styled from 'styled-components';

export const StyledSelect = styled(Select)`
  width: 100%;
  height: 42px;
  font-size: 14px;
  font-family: 'Montserrat';
  border: 0 !important;
  outline: 0 !important;
  box-shadow: 0 0 0 1.5px #e9e9e9;
  font-family: 500;
  background-color: ${(p) => (p.disabled ? '#e9e9e9' : '#fff')} !important;
  border-radius: 4px 8px;

  > .ant-select-selector {
    padding: 0 !important;
    height: 40px !important;
    border: none !important;

    .ant-select-selection-search {
      > input {
        height: 100% !important;
      }
    }

    > .ant-select-selection-placeholder {
      display: flex;
      padding-left: 11px !important;
      align-items: center;
    }
    > .ant-select-selection-item {
      display: flex;
      align-items: center;
      padding-left: 11px !important;
      background-color: ${(p) => (p.disabled ? '#e9e9e9' : '#fff')};
    }
  }

  @media (max-width: 768px) {
    height: 40px;
  }
  @media (max-width: 575px) {
    height: 50px;
  }
  &:focus {
    outline: none;
  }
`;

export const StyledLabel = styled.label`
  font-size: 15px;
  font-weight: 600;
  font-family: 'Montserrat';
  margin: 0.5rem 0;
`;
