import styled from 'styled-components';

export const SectionHeading = styled.h3`
  margin: 0;
  padding: 0;
  color: #6b39e8;
  font-family: 'Montserrat';
  font-size: ${(p) => p.size || '18px'};
  font-weight: 700;
  margin-bottom: ${(p) => p.mb || '0'};
`;

export const Responsive = styled.div`
  @media (max-width: 992px) {
    margin-bottom: 2rem;
  }
`;
