import { Select } from 'antd';
import { StyledLabel, StyledSelect } from './styles/main';

const { Option } = Select;

const CustomButton = ({ selectType, text, label, options, ...rest }) => {
  const getInput = () => {
    switch (selectType) {
      case 'standard':
        return (
          <>
            {label && <StyledLabel>{label}</StyledLabel>}
            <StyledSelect {...rest}>
              {options.map((el, index) => (
                <Option
                  key={index}
                  value={el?.toUpperCase()}
                  style={{
                    height: '42px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {el}
                </Option>
              ))}
            </StyledSelect>
          </>
        );

      default:
        return <Select {...rest}>{text}</Select>;
    }
  };
  return <>{getInput()}</>;
};

export default CustomButton;
