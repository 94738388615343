import { Fragment } from 'react';
import { CellText, ResponsiveViewHeading } from '../../components/Table';
import moment from 'moment';

const formatDate = (date) => {
  return `${moment(date).format('MM')}/${moment(date).format('DD')}/${moment(
    date
  ).format('YYYY')} `;
};

const renderText = (text) => <CellText>{text}</CellText>;
const renderDate = (date) => <CellText>{formatDate(date)}</CellText>;

export const columns = [
  {
    title: () => (
      <Fragment>
        Organization Name
        <br />
        Date Created
        <br />
        Status
        <br />
        Plan
      </Fragment>
    ),
    render: (record) => (
      <Fragment>
        <ResponsiveViewHeading>Organization Name:</ResponsiveViewHeading>
        &nbsp;
        <CellText width={200}>{record.name}</CellText>
        <br />
        <ResponsiveViewHeading>Date Created:</ResponsiveViewHeading>&nbsp;
        <CellText>{formatDate(record.createdAt)}</CellText>
        <br />
        <ResponsiveViewHeading>Status:</ResponsiveViewHeading>&nbsp;
        <CellText>{record.state}</CellText>
        <br />
        <ResponsiveViewHeading>Plan:</ResponsiveViewHeading>&nbsp;
        <CellText>{record.plan}</CellText>
      </Fragment>
    ),

    responsive: ['xs'],
  },
  {
    title: 'Organization Name',
    dataIndex: 'name',
    key: 'name',
    render: renderText,
    responsive: ['sm'],
  },
  {
    title: 'Date Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: renderDate,
    responsive: ['sm'],
  },
  {
    title: 'Status',
    dataIndex: 'state',
    key: 'state',
    render: renderText,
    responsive: ['sm'],
  },
  {
    title: 'Plan',
    dataIndex: 'plan',
    key: 'plan',
    render: renderText,
    responsive: ['sm'],
  },
];
