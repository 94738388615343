import { configureStore, combineReducers } from '@reduxjs/toolkit';
import allReducers from './slices';

const rootReducer = combineReducers(allReducers);

const store = configureStore({
  reducer: rootReducer,
});

export default store;
