import React, { useState } from "react";
import axios, { baseUrl } from "../../utils/axios";
import { urls } from "../Router/urls";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { Row, Col, message } from "antd";
import Select from '../../components/Select';
import {
  FlexContainer,
  PageContainer,
  PageHeading,
} from "../../components/Common";
import { useHistory } from "react-router-dom";

const CreateOrganization = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [orgEmail, setOrgEmail] = useState("");
  const [planId, setPlanId]= useState('Premium')
  const create = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post(`${baseUrl}/api/v2/organization`, {
        organization: orgName,
        email: orgEmail,
        // need to be fixed
        planId: planId === 'Premium' ? 3:1,
      });
      message.success(`Organization created successfully!`);
      history.push(urls.listOrganizations);
    } catch (err) {
      if (err?.response?.data?.message)
        message.error(err.response.data.message);
      else message.error("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer>
      <FlexContainer mb="1rem">
        <PageHeading>Create Organization</PageHeading>
      </FlexContainer>

      <Row justify="center">
        <Col md={12} xl={8}>
          <form onSubmit={create}>
            <Input
              inputType="standardForm"
              label="Organization Name"
              placeholder="Name"
              mb="0.8rem"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
            />
            <Input
              inputType="standardForm"
              label="Email"
              placeholder="Email..."
              mb="0.8rem"
              value={orgEmail}
              onChange={(e) => setOrgEmail(e.target.value)}
            />
            <Select
              selectType="standard"
              value={planId}
              onChange={(value) => setPlanId(value)}
              options={["Basic", "Premium"]}
              label="Role"
              />
            <FlexContainer justify="flex-end" mt="1rem">
              <Button
                btnType="standardGreen"
                text="Create"
                small
                disabled={loading}
                htmlType="submit"
              />
            </FlexContainer>
          </form>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default CreateOrganization;
