import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import Home from '../Home';
import Login from '../Login';
import ListOrganizations from '../ListOrganizations';
import { PrivateRoute } from './PrivateRoute';
import { OfflineRoute } from './OfflineRoute';
import { urls } from './urls';
import OrganizationDetail from '../OrganizationDetail';
import CreateOrganization from '../CreateOrganization';

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <OfflineRoute exact path={urls.home} component={Home} />
        <OfflineRoute exact path={urls.login} component={Login} />
        <OfflineRoute exact path={urls.forgotPassword} component={Login} />
        <PrivateRoute
          exact
          path={urls.listOrganizations}
          component={ListOrganizations}
        />
        <PrivateRoute
          exact
          path={'/admin/organizations/:id'}
          component={OrganizationDetail}
        />
        <PrivateRoute
          exact
          path={urls.createOrganization}
          component={CreateOrganization}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
