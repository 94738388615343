import styled from 'styled-components';
import { Button } from 'antd';

export const StyledAuthButton = styled(Button)`
  min-width: 190px;
  height: 45px;
  background-color: white !important;
  color: #1877f2;
  font-size: 18px;
  margin: 0;
  padding: 0;
  border-radius: 8px;
  font-weight: 500;
`;
