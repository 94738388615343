import React, { useState, useContext } from 'react';
import { message, Modal, Row } from 'antd';
import { SectionHeading } from '../styles/main';
import { FlexContainer } from '../../../components/Common';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import axios, { baseUrl } from '../../../utils/axios';
import { OrgUUIDContext } from '../../../context';

const AddModal = ({ onCancel, setUserList, ...rest }) => {
  const orgUUID = useContext(OrgUUIDContext);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('Recruiter');
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    resetFields();
    onCancel();
  };

  const resetFields = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post(`${baseUrl}/api/v2/organization/user`, {
        firstName,
        lastName,
        email,
        organizationID: orgUUID,
      });
      message.success('User added successfully!');
      setUserList((prev) => [...prev, { firstName, lastName, role }]);
      closeModal();
    } catch (err) {
      message.error('Error adding user');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal {...rest} closable={false} onCancel={closeModal}>
      <Row justify="center">
        <SectionHeading size="22px">Add User</SectionHeading>
      </Row>
      <form onSubmit={submit}>
        <Input
          inputType="standardForm"
          required
          placeholder="John"
          label="First Name"
          mb="0.8rem"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Input
          inputType="standardForm"
          required
          placeholder="Doe"
          label="Last Name"
          mb="0.8rem"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <Input
          inputType="standardForm"
          placeholder="john_doe@example.com"
          label="Email"
          type="email"
          required
          mb="0.8rem"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Select
          selectType="standard"
          value={role}
          options={['Admin', 'Recruiter']}
          label="Role"
          disabled
          onChange={(value) => setRole(value)}
        />

        <FlexContainer justify="flex-end" mt="1rem">
          <Button
            btnType="standardGreen"
            small
            text="ADD USER"
            htmlType="submit"
            disabled={loading}
          />
        </FlexContainer>
      </form>
    </Modal>
  );
};

export default AddModal;
