import { Col } from 'antd';
import styled from 'styled-components';

export const StyledCol = styled(Col)`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LoginBackgroundContainer = styled(Col)`
  background: rgba(255, 255, 255, 0.3);
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: 'center';
  align-items: center;
  border-radius: 5px;
  /* @media (max-width: 1024px) {
    width: 90%;
  } */
`;

export const Logo = styled.img`
  width: 180px;
  height: auto;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0;
  width: 100%;
`;
