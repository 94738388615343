import axios from 'axios';

export const baseUrl = process.env.REACT_APP_API_URI || 'http://localhost:5000';

// All request interceptors to be placed here
axios.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem('BcixAdminData'))?.token;
  config.headers.common.Authorization = `${token}`;
  return config;
});

// All response interceptors to be placed here
axios.interceptors.response.use(
  (res) => {
    return Promise.resolve(res);
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default axios;
